// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyB47UDYuASSQ1sbnGRBDt8ytvNc0BTeKYM",
  authDomain: "bengo-11b81.firebaseapp.com",
  databaseURL: "https://bengo-11b81.firebaseio.com",
  projectId: "bengo-11b81",
  storageBucket: "bengo-11b81.appspot.com",
  messagingSenderId: "503182387433",
  appId: "1:503182387433:web:6b22a0d0452d934582e878",
  measurementId: "G-QH62KHXQP2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);


export {
    firestore,
    storage
}