import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Root from './pages/root/Root';
import NavBar from './components/NavBar/NavBar';

function App() {

  return (
    <div className="App flexCenter">
      <BrowserRouter>
        <Routes>
          <Route path='/*' Component={Root}>

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
