import React, { useEffect, useRef, useState } from 'react'
import './NavBar.css'
import { useLocation, useNavigate } from 'react-router'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import AboutIcon from '@mui/icons-material/AccountBoxOutlined';
import ProjectIcon from '@mui/icons-material/WorkOutline';
import ContactPageIcon from '@mui/icons-material/ContactPageOutlined';
import SportsEsportsIcon from '@mui/icons-material/SportsEsportsOutlined';

type iNav = {
    route: string,
    name: string,
    id: string,
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    selected?: boolean
}

function NavBar() {
    const loc = useLocation();
    const nav = useNavigate();
    const [ref] = useState<{current: HTMLDivElement | null}>({current:null});
    const [floating, setFloating] = useState<boolean>(false);
    const [navs, setNavs] = useState<iNav[]>([
        {
            name: "Home",
            route: "/",
            id: "home",
            icon: HomeIcon
        },
        {
            name: "About",
            route: "/about",
            id: 'about',
            icon: AboutIcon
        },
        {
            name: "Portfolio",
            route: "/portfolio",
            id: "portfolio",
            icon: ProjectIcon
        },
        {
            name: "Contact",
            route: "/contact",
            id: "contact",
            icon: ContactPageIcon
        },
        {
            name: "Play Bingo!",
            route: "",
            id: "game",
            icon: SportsEsportsIcon
        },
    ]);

    const tabClicked = (x: iNav)=>{
        if(x.id === "game"){
            window.open("https://bingo-34dd8.web.app/");
        }
        else{
            nav(x.route);
        }
    }

    useEffect(()=>{
        let root = document.querySelector("#root");
        if(root){
            ref.current = root as HTMLDivElement;
        }
        root?.addEventListener("scroll", (e)=>{
            let el = e.target as HTMLDivElement;
            if(el.scrollTop > 100){
                setFloating(true)
            }else{
                setFloating(false);
            }
        })
    }, []);

    useEffect(()=>{
        let path = loc.pathname.split("/")[1] || "home";
        setNavs(navs.map((x)=>{
            if(x.id.includes(path)){
                x.selected = true;
            }else{
                x.selected = false;
            }
            return x
        }));
    }, [loc.pathname])

  return (
    <div className={`flexCenter width100 navBar ${floating && "floating"}`}>
        <div className="navLeft flexCenter">
            <span className="name">Amal Dev</span>
        </div>
        <div className="navRight flexCenter">
            {
                navs.map((x)=>{
                    return (
                        <>
                            <div className="sec navText flexCenter" onClick={()=>{tabClicked(x)}}>
                                <a href="">{x.name}</a>
                            </div>
                            <span className={`navLogo flexCenter ${x.selected ? "selected" : ''}`} onClick={()=>{tabClicked(x)}}>
                                <x.icon fontSize='large' color='inherit'/>
                            </span>
                        </>
                    )
                })
            }
        </div>
    </div>
  )
}

export default NavBar