import React, { useEffect, useRef, useState } from 'react'
import './Root.css'
import NavBar from '../../components/NavBar/NavBar'
import Button from '../../components/Button/Button'
import Input, { iInput } from '../../components/Input/Input'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YoutubeIcon from '@mui/icons-material/YouTube';
import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'
import { useLocation, useNavigate } from 'react-router'
import { getAllComments, getProjects, postCommnet } from '../../firebase/db'
import { iComment, iProject } from '../../interface'
import emailjs from '@emailjs/browser';
import { ref as storageRef, getDownloadURL } from 'firebase/storage'
import { storage } from '../../firebase/config'

type iCircle = {
    height: string,
    width: string,
    top: string,
    bottom: string,
    left: string,
    right: string
}

type iImage = {
    backgroundImage: string
}

type iExperience = {
    designation: string,
    date: string,
    description: string,
    company: string,
    link: string
}

type iEducation = {
    course: string,
    date: string,
    description: string,
    university: string,
    link: string
}

type iSocial = {
    link: string,
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
}

function Root() {
    const loc = useLocation();
    const introRef = useRef<HTMLDivElement>(null);
    const [location, setLocation] = useState<string>("");
    const [skillImages, setSkillImages] = useState<iImage[]>([
        {backgroundImage: "url('./html.png')"},
        {backgroundImage: "url('./css.png')"},
        {backgroundImage: "url('./js.png')"},
        {backgroundImage: "url('./ts.png')"},
        {backgroundImage: "url('./react.png')"},
        {backgroundImage: "url('./rn.png')"},
        {backgroundImage: "url('./angular.png')"},
        {backgroundImage: "url('./vue.png')"},
    ]);
    const [experiences, setExperiences] = useState<iExperience[]>([
        {
            company: "Sysnatura",
            date: "March 2023 to April 2024",
            description: "Worked as a Junior Software Developer specializing in front-end development. Collaborated with diverse teams on various projects utilizing technologies such as Angular, React, and Vue.js. Demonstrated the ability to quickly learn new technologies and consistently excelled in tasks assigned by my manager. ",
            designation: "Junior Software Developer",
            link: "https://sysnatura.com/"
        },
        {
            company: "Shellsquare",
            date: "February 2022 to January 2023",
            description: "Worked as a front-end developer. Collaborated with diverse teams on various projects utilizing technologies such as Angular, React, and Vue.js. Demonstrated the ability to quickly learn new technologies and consistently excelled in tasks assigned by my manager.",
            designation: "Front End Developer",
            link: "https://www.shellsquare.com/"
        },
        {
            company: "Quest Innovative",
            date: "September 2021 to January 2023",
            description: "Undergone training for python full stack development",
            designation: "Python Fullstack Developer",
            link: "https://www.qis.co.in/"
        },
    ]);

    const [education, setEducation] = useState<iEducation[]>([
        {
            course: "10th Level",
            date: "August 2014 to August 2015",
            description: "Achieved a CGPA of 8.0",
            university: "Central Board Of Secondary Educ.",
            link: "https://www.cbse.gov.in/cbsenew/cbse.html"
        },
        {
            course: "12th Level",
            date: "June 2016 to April 2017",
            description: "11th and 12th level academic periods was not great for me. I was forced to take BIO-MATHS group by my parents and All my friends from 10th grade went to other schools or other division and I lost interest in everything",
            university: "Central Board Of Secondary Educ.",
            link: "https://www.cbse.gov.in/cbsenew/cbse.html"
        },
        {
            course: "B.Tech in Computer Science & Engg.",
            date: "August 2017 to August 2021",
            description: "Graduated B.Tech Computer Science and engineering in 2021 with a CGPA of 6.9. I lead the team for the final year project 'DROWSINESS DETECTION SYSTEM' and achieved maximum grade for the project.",
            university: "Kerala Technological University",
            link: "https://ktu.edu.in/"
        },
    ]);

    const [inputs, setInputs] = useState<(iInput & {id: string})[]>([
        {
            inputType: "input",
            placeHolder: "Your Name",
            value: "",
            id: "name"
        },
        {
            inputType: "input",
            placeHolder: "Email",
            value: "",
            id: "email"
        },
        {
            inputType: "input",
            placeHolder: "Subject",
            value: "",
            id: "subject"
        },
        {
            inputType: "textArea",
            placeHolder: "Message",
            value: "",
            id: "message"
        },
    ]);

    const [social, setSocial] = useState<iSocial[]>([
        {
            link: 'https://www.facebook.com/profile.php?id=100007113914850',
            icon: FacebookIcon
        },
        {
            link: 'https://www.instagram.com/amal_x_dev/',
            icon: InstagramIcon
        },
        {
            link: 'https://github.com/amal-dev-10/',
            icon: GitHubIcon
        },
        {
            link: 'https://www.linkedin.com/in/amal-dev-112653219/',
            icon: LinkedInIcon
        },
        {
            link: 'https://www.youtube.com/channel/UCIAZ8gTq1eiqAuyGj-ah1RQ',
            icon: YoutubeIcon
        },
    ])

    const [projects, setProjects] = useState<iProject[]>([]);
    const [selProject, setSelProject] = useState<iProject | null>(null);
    const [circles, setCircles] = useState<iCircle[]>([]);
    const nav = useNavigate();
    const maxCircles: number = 20;
    const maxCircleDiamter: number = 150;
    const [commentVal, setCommentVal] = useState<string>("");
    const [ref] = useState<{current: HTMLDivElement | null}>({current:null});

    const emailInpuChanged = (id: string, val: string)=>{
        let i = inputs.map((x)=>{
            if(x.id === id){
                x.value = val;
            }
            return x
        });
        setInputs(i)
    }

    const sendMailClicked = async ()=>{
        try{
            let valid = inputs.every((x)=> x.value);
            if(valid){
                await emailjs.send(
                    "service_4grwfxv", 
                    "template_2zqn57n", 
                    {
                        "from_name": inputs[0].value,
                        "to_name": "Amal Dev",
                        "message": inputs[3].value,
                        "subject": inputs[2].value,
                        "from_email": inputs[1].value
                    },
                    {publicKey: "2zCwIhzxunku_NK0e"}
                );
                setInputs(inputs.map((x)=> {x.value = ""; return x}));
            }else{
                alert("Fill all the fields.")
            }
        }catch(err){
            console.log(err)
        }
    }

    const designClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
        let x = e.clientX;
        let y = e.clientY;
        let dim = (Math.random() * maxCircleDiamter);
        setCircles(
            [...circles, {
                left: `${x-(dim/2)}px`,
                top: `${y-(dim/2)}px`,
                bottom: 'unset',
                right: 'unset',
                height: `${dim}px`,
                width: `${dim}px`
            }]
        )
    }

    const postNewComment = async ()=>{
        if(commentVal){
            if(selProject){
                let res: iComment[] = await postCommnet({
                    content: commentVal,
                    createdDate: new Date().toUTCString(),
                    projectId: selProject?.id || "",
                    id: new Date().getTime().toString()
                });
                if(res && res?.length && res != null){
                    setCommentVal("");
                    setProjects(projects.map((x)=>{
                        let finCom = x.comments?.find((y)=> y.id === res[0]?.id)
                        if(selProject?.id === x.id && !finCom){
                            if(x?.comments?.length){
                                x.comments = [...res, ...x.comments]
                            }else{
                                x.comments = [...res]
                            };
                        }
                        return x
                    }));
                    if(selProject){
                        setSelProject({...selProject, comments: [...res, ...(selProject?.comments || [])]})
                    }
                }else{
                    alert("Something went wrong.")
                }
            }
        }else{
            alert("Type comment to post.")
        }
    }

    const getData = async (path: string)=>{
        let id = loc.pathname.split("/")?.[2];
        if(path === "portfolio"){
            if(!projects.length){
                let res: iProject[] = await getProjects("all");
                if(!id){
                    nav(`/portfolio/${res[0].id}`)
                }
                let find: iProject | undefined = res.find((x)=> x?.id === id);
                if(find){
                    setSelProject(find);
                }else{
                    setSelProject(res?.[0] || null)
                }
                setProjects([...res]);
            }else{
                let find: iProject | undefined = projects.find((x)=> x?.id === id);
                if(find){
                    setSelProject(find);
                }
            }
        }
    }

    useEffect(()=>{
        if(projects.length){
            getComments();
        }
    }, [projects])

    const getComments = async ()=>{
        let path = loc.pathname.split("/")[1];
        if(path === "portfolio"){
            let id = loc.pathname.split("/")?.[2];
            if(id){
                let comm: iComment[] = await getAllComments(id);
                setProjects(projects.map((x)=>{
                    if(x.id === id){
                        x.comments = comm;
                    }
                    return x
                }));
            }
        }
    }

    const renderCircles = ()=>{
        if(introRef.current){
            let windowWidth = introRef.current.clientWidth;
            let windowHeight = introRef.current.clientHeight;
            let  t: iCircle[] = []
            for( let i=0; i<=maxCircles; i++){
                let r1 = (Math.random() * (windowHeight - maxCircleDiamter));
                let r2 = (Math.random() * (windowHeight - maxCircleDiamter));
                let r3 = (Math.random() * (windowWidth - maxCircleDiamter));
                let r4 = (Math.random() * (windowWidth - maxCircleDiamter));
                let dim = (Math.random() * maxCircleDiamter);
                t.push(
                    {
                        bottom: `unset`,
                        height: `${dim}px`,
                        left: `${Math.floor(r3)}px`,
                        right: `unset`,
                        top: `${Math.floor(r1)}px`,
                        width: `${dim}px`
                    } as iCircle
                )
            }
            setCircles(t);
        }
    }

    useEffect(()=>{
        renderCircles();
    }, [introRef.current, location]);

    const scrollTo = (direction: "top" | "bottom")=>{
        if(ref.current){
            if(direction === "bottom"){
                ref.current.scrollTo({
                    top: ref.current.scrollHeight,
                    behavior: "smooth"
                });
            }else if(direction === "top"){
                ref.current.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        };
    }

    const downloadResume = async ()=>{
        try{
            const pdfRef = storageRef(storage, "gs://bengo-11b81.appspot.com/resume/AMAL DEV.pdf");
            const downloadUrl = await getDownloadURL(pdfRef);

            const link = document.createElement("a");
            link.href = downloadUrl;
            link.target = "_blank";
            link.download = "resume.pdf"
            link.click();
        }catch(err){
            console.log(err)
        }
    }

    useEffect(()=>{
        let l = loc.pathname.split("/")[1] || "home";
        let id = loc.pathname.split("/")?.[2];
        let title = document.querySelector("#tabTitle");
        if(title){
            title.innerHTML = (l[0]?.toUpperCase() + l.slice(1)) || "Home"
        }
        if(l === "contact"){
            scrollTo("bottom");
        }else if(l === "portfolio" && id){

        }
        else{
            scrollTo("top");
        }
        setLocation(l);
        getData(l);
    }, [loc.pathname]);

    useEffect(()=>{
        let root = document.querySelector("#root");
        if(root){
            ref.current = root as HTMLDivElement;
        }
    }, [])

  return (
    <div className='flexCenter root width100 height100'>
        <NavBar/>
        <div className={`introSection flexCenter ${location !== "home" ? "header" : ''}`} ref={introRef}>
            <div className="textWrapper" onClick={(e)=>{designClicked(e)}}>
                <div className="description flexCenter">
                    <span className="name">Amal Dev</span>
                    <span className="designation">{location === "home" ? "MERN Stack Developer / Front End Developer" : location.toUpperCase()}</span>
                    {
                        location === "home" &&
                        <>
                            <span className="introduction">
                                Hi, I am Amal Dev, self taught and experienced web and mobile developer.
                            </span>
                            <Button
                                btnText='HIRE ME'
                                onClick={()=>{scrollTo("bottom")}}
                                theme='theme1'
                            />
                        </>
                    }
                </div>  
                {
                    circles.map((x)=>{
                        return (
                            <div className="circleDesign" style={{...x}}>

                            </div>
                        )
                    })
                }
            </div>
        </div>
        {
            location === "portfolio" && 
            <div className="portfolioSection flexCenter">
                <div className="portfolioWrapper flexCenter">
                    <div className="portfolioLeft flexCenter width100">
                        <div className="projectTitleWrapper flexCenter width100">
                            <div className="logo img" style={{backgroundImage: `url("${selProject?.logo}")`}}></div>
                            <div className="titleWrapper flexCenter">
                                <span className="title">{selProject?.title}</span>
                                <span className="date">{selProject?.date}</span>
                            </div>
                        </div>
                        <div className="techUsed flexCenter width100">
                            {
                                selProject?.techUsed.map((x)=>{
                                    return (
                                        <div className="techImage img" style={{backgroundImage: `url("${x}")`}}></div>
                                    )
                                })
                            }
                        </div>
                        <div className="firstParaAndImage flexCenter width100">
                            <span className="content">
                                {selProject?.firstPara}
                            </span>
                            <div className="image1 img" style={{backgroundImage: `url("${selProject?.introImg}")`}}></div>
                        </div>
                        <div className="secondContent flexCenter width100">
                            <span className="content">{selProject?.secondPara}</span>
                        </div>
                        <div className="screeShotsDiv flexCenter width100">
                            <div className="titleWrapper flexCenter width100">
                                <span className="title">Screenshots</span>
                            </div>
                            <div className="screenshotsContainer flexCenter width100">
                                <div className="screenshotWrapper flexCenter width100">
                                    {
                                        selProject?.screenshots?.map((x)=>{
                                            return (
                                                <span className="screenshot img" style={{backgroundImage: `url("${x}")`}}></span>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="commentSection flexCenter width100">
                            <div className="commentInputWrapper flexCenter width100">
                                <span className="title">Add comments</span>
                                <Input
                                    inputType='textArea'
                                    placeHolder='Type comment'
                                    value={commentVal}
                                    type='text'
                                    onChange={(e: string)=>{setCommentVal(e)}}
                                />
                                <Button
                                    btnText='Post'
                                    theme='theme1'
                                    onClick={()=>{postNewComment()}}
                                />
                            </div>
                            <div className="comments flexCenter width100">
                                {
                                    selProject?.comments?.map((x)=>{
                                        return (
                                            <div className="comment flexCenter width100">
                                                <span className="timePosted">{new Date(x.createdDate).toDateString()}</span>
                                                <span className='content'>{x.content}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="portfolioRight flexCenter">
                        <div className="projectMenuWrapper flexCenter">
                            {
                                projects.map((x)=>{
                                    return (
                                        <>
                                            <span 
                                                className={`project textSel ${selProject?.id === x.id ? "selected" : ''}`}
                                                onClick={()=>{nav(`portfolio/${x.id}`)}}
                                            >{x.title}</span>
                                            <span 
                                                className={`img iconSel ${selProject?.id === x.id ? "selected" : ''}`} 
                                                style={{backgroundImage: `url('${x.logo}')`}}
                                                onClick={()=>{nav(`portfolio/${x.id}`)}}
                                            ></span>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
        {
            location !== "portfolio" && location != "contact" &&
            <div className="aboutSection flexCenter">
                <div className="aboutInnerCard flexCenter">
                    <div className="cardTop flexCenter">
                        <span className="title">ABOUT MYSELF</span>
                        <span className="aboutText">
                            I'm a 2021 B.Tech Computer Science & Engineering graduate. Passionate about creating web, mobile apps and getting updated on technologies often.
                        </span>
                        <Button
                            btnText='DOWNLOAD RESUME'
                            onClick={()=>{downloadResume()}}
                            theme='theme2'
                        />
                    </div>
                    <div className="cardBottom flexCenter">
                        {
                            skillImages.map((x)=>{
                                return (
                                    <div className="imageDiv" style={{...x}}></div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        }
        {
            location !== "portfolio" && location != "contact" &&
            <div className="experienceSection flexCenter width100">
                <div className="experienceTitleWrapper flexCenter width100">
                    <span className="sectionTitle">Experience</span>
                </div>
                <div className="experiences flexCenter">
                    {
                        experiences.map((x)=>{
                            return (
                                <div className="experience flexCenter width100">
                                    <div className="experienceTop flexCenter width100">
                                        <div className="experienceTopLeft flexCenter">
                                            <span className="experienceTitle">{x.designation}</span>
                                            <span className="date">{x.date}</span>
                                        </div>
                                        <div className="experienceTopRight flexCenter">
                                            <span>at <a className='companyLink' target="_blank" rel="noopener noreferrer" href={x.link}>{x.company}</a></span>
                                            <OpenInNewIcon fontSize='medium'/>
                                        </div>
                                    </div>
                                    <div className="experienceBottom flexCenter width100">
                                        <span className="experienceDesc width100">
                                            {x.description}
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        }
        {
            location !== "portfolio" && location != "contact" &&
            <div className="expertiseSection flexCenter width100">
                <span className="sectionTitle">My Expertise</span>
                <div className="expertiseCard flexCenter">
                    <div className="expertiseCardLeft flexCenter">
                        <span className="text">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged
                        </span>
                        <span className="caret"></span>
                    </div>
                    <div className="expertiseCardRight" style={{backgroundImage: 'url("./expertise.avif")'}}></div>
                </div>
            </div>
        }
        {
            location !== "portfolio" && location != "contact" &&
            <div className="experienceSection flexCenter width100">
                <div className="experienceTitleWrapper flexCenter width100">
                    <span className="sectionTitle">Education</span>
                </div>
                <div className="experiences flexCenter">
                    {
                        education.map((x)=>{
                            return (
                                <div className="experience flexCenter width100">
                                    <div className="experienceTop flexCenter width100">
                                        <div className="experienceTopLeft flexCenter">
                                            <span className="experienceTitle">{x.course}</span>
                                            <span className="date">{x.date}</span>
                                        </div>
                                        <div className="experienceTopRight">
                                            <span>at <a className='companyLink' target="_blank" rel="noopener noreferrer" href={x.link}>{x.university}</a></span>
                                        </div>
                                    </div>
                                    <div className="experienceBottom flexCenter width100">
                                        <span className="experienceDesc width100">
                                            {x.description}
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        }
        <div className="footer flexCenter width100">
            <div className="footerWrapper">
                <div className="quoteWrapper width100">
                    <span className='quote'>
                    "Opportunity is the canvas where skill becomes art. Give me the chance to paint the masterpiece."
                    </span>
                </div>
                <div className="footerContent flexCenter width100">
                    <div className="footerContentLeft flexCenter">
                        <span className="title">DROP A LINE</span>
                        <div className="inputWrapper flexCenter width100">
                            {
                                inputs.map((x)=>{
                                    return (
                                        <Input
                                            inputType={x.inputType}
                                            onChange={(e: string)=>{emailInpuChanged(x.id, e)}}
                                            placeHolder={x.placeHolder}
                                            value={x.value}
                                        />
                                    )
                                })
                            }
                        </div>
                        <div className="buttonWrapper flexCenter width100">
                            <Button
                                btnText='Send Message'
                                onClick={()=>{sendMailClicked()}}
                                theme='theme3'
                            />
                        </div>
                    </div>
                    <div className="footerContentRight flexCenter">
                        <div className="contactWrapper flexCenter">
                            <span className="title">CONTACT ME</span>
                            <div className="contactDetailWrapper flexCenter">
                                <span className="pD">+91 7902992447</span>
                                <span className="pD">amaldev1214@gmail.com</span>
                                <Button
                                    btnText='DOWNLOAD RESUME'
                                    onClick={()=>{downloadResume()}}
                                    theme='theme1'
                                />
                            </div>
                            <div className="socialsWrapper flexCenter width100">
                                {
                                    social.map((x)=>{
                                        return (
                                            <a href={x.link} target="_blank" rel="noopener noreferrer">
                                                <span className="iconWrapper social flexCenter">
                                                    <x.icon fontSize='medium' color='inherit' className='icon'/>
                                                </span>
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Root