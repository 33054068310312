import React from 'react'
import './Button.css'

type props = {
    btnText: string,
    onClick: any,
    theme: "theme1" | "theme2" | "theme3"
}

function Button(props: props) {
  return (
    <div className={`button ${props.theme} flexCenter`} onClick={()=>{props.onClick()}}>
        <span className="buttonText">{props.btnText}</span>
    </div>
  )
}

export default Button