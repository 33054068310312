import { collection, doc, getDoc, getDocs, query, setDoc, where  } from "firebase/firestore"
import { firestore } from "./config"
import { iComment } from "../interface"

export const getProjects = async (id: string)=>{
    let res: any = null
    if(id === "all"){
        let q = query(collection(firestore, "projects"))
        res = (await getDocs(q)).docs.map((x)=> {return {id: x.id, ...x.data()}});
    }else if(id){
        res = (await getDoc(doc(firestore, `projects/${id}`))).data();
    }
    return res
}

export const postCommnet = async (data: iComment)=>{
    try{
        await setDoc(doc(firestore, `comments/${data.id}`), {
            ...data
        });
        return [data]
    }catch(err){
        console.log(err);
        return []
    }
}

export const getAllComments = async (id: string)=>{
    let res: any = null
    let q = query(collection(firestore, "comments"), where("projectId", "==", id))
    res = (await getDocs(q)).docs.map((x)=> {return {id: x.id, ...x.data()}});
    return res
}