import React, { useEffect, useState } from 'react'
import './Input.css'

export type iInput = {
    placeHolder: string,
    onChange?: any,
    type?: string,
    inputType: "textArea" | "input",
    value: string
}

function Input({ inputType, onChange, placeHolder, type, value }: iInput) {
    const [val, setVal] = useState<string>(value);

    useEffect(()=>{
        setVal(value)
    }, [value])
  return (
    <div className='inputDiv flexCenter width100'>
        {
            inputType === "input" &&
            <input type={type || "text"} placeholder={placeHolder} value={val} onChange={(e)=>{setVal(e.target.value); onChange(e.target.value)}}/>
        }  
        {
            inputType === "textArea" &&
            <textarea placeholder={placeHolder} value={val} onChange={(e)=>{setVal(e.target.value); onChange(e.target.value)}}></textarea>
        }
    </div>
  )
}

export default Input